



























































import AuthService from "@/services/AuthService";
import { Component, Vue } from "vue-property-decorator";
import router from "../router/index";

@Component
export default class Login extends Vue {
  private username: string = "";
  private password: string = "";
  private hasError: boolean = false;
  private error: string = "";
  private init: string = router.currentRoute.query.init as string;

  login() {
    this.hasError = false;

    AuthService.login(this.username, this.password)
      .then((response) => {
        if (this.init === "1") {
          router.push({ name: "settings", query: { init: "1" } });
        } else if (response.data.roles.includes("ROLE_STAFF")) {
          router.push({ name: "userroster" });
        } else {
          router.push({ name: "home" });
        }
      })
      .catch((e) => {
        this.hasError = true;
        if (e.response.status === 401) {
          this.error = "Ungültiger Benutzername oder Passwort";
        } else if (e.response.status === 403) {
          this.error = "Es fehlen Berechtigungen für die Nutzung der Anwendung";
        } else if (e.response.status === 422) {
          this.error =
            "Ihr Konto ist gesperrt, da es noch nicht über die Aktivierungsmail freigeschaltet wurde.";
        } else {
          this.error = "Fehler bei der Anmeldung";
        }
        console.log(e);
      });
  }

  reset() {
    router.push({ name: "resetpassword" });
  }
}
